import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';

import {
  IAllNews,
  INewsBlock,
  Landingpage,
} from 'epromo-lib-cms/types/payload-types';

import { createQueryClient } from 'epromo-lib-cms/services/gql-client';
import { getServerSideHelpers } from 'epromo-lib/utils/helpers';
import { ResolveCmsComponent } from 'ui/utils/cmsHelpers';

import { IEpromoPageProps } from 'epromo-types';
import { LandingLayout } from 'ui/components/layouts/LandingLayout';
import { getLandingPageData } from 'epromo-lib-cms/services/cms-requests';

const PageHead = dynamic(
  () => import('@components/molecules/PageHead').then((mod) => mod.PageHead),
  {
    ssr: true,
    loading: () => <div></div>,
  }
);

export interface HomePageProps extends IEpromoPageProps {
  landingPage: ILandingPage;
  tokenExists: boolean;
}

const Home = ({
  landingPage,
  footerData,
  tokenExists,
  deviceInfo,
  closedNotifications,
  notificationToDisplay,
  nativeAppNotificationStatus,
}: HomePageProps) => {
  const landingPageSections = landingPage.Landingpage.sections;

  return (
    <LandingLayout
      footerData={footerData}
      tokenExists={tokenExists}
      deviceInfo={deviceInfo}
      closedNotifications={closedNotifications}
      notificationToDisplay={notificationToDisplay}
      nativeAppNotificationStatus={nativeAppNotificationStatus}
    >
      <PageHead
        meta={landingPage.Landingpage.meta}
        seoExtra={landingPage.Landingpage.seoExtra}
        slug={landingPage.Landingpage.slug}
      />
      <div className="relative h-full w-auto">
        {landingPage &&
          landingPageSections.map((section, idx) => (
            <ResolveCmsComponent
              key={`${section.id}_${idx}`}
              block={section}
              deviceInfo={deviceInfo}
            />
          ))}
      </div>
    </LandingLayout>
  );
};

interface ILandingPage {
  Landingpage: Landingpage;
  allNews: IAllNews;
}

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  req,
  query,
}) => {
  const client = createQueryClient();
  const response = await getLandingPageData(locale);

  const { toLogin } = query;

  if (toLogin === 'true') {
    return {
      props: {},
      redirect: {
        destination: 'auth/login',
      },
    };
  }

  const newsBlock = (response?.Landingpage.sections || []) // @ts-ignore
    .find((sec) => sec['__typename'] === 'Newsblock');

  if (newsBlock) {
    // @ts-ignore
    (newsBlock as INewsBlock).newsItems = response.allNews.docs || [];
  }

  const { localeToUse, tokenExists } = getServerSideHelpers(locale, req);

  return {
    props: {
      // eslint-disable-next-line max-len
      ...(await serverSideTranslations(localeToUse, ['common'], {
        reloadOnPrerender: true,
        i18n: {
          locales: process.env.ENABLED_LANGUAGES?.split(';') ?? [],
          defaultLocale: 'lt',
        },
      })),
      landingPage: response,
      tokenExists,
    },
  };
};

export default Home;
