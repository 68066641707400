import { ReactNode, useState } from 'react';
import { InView } from 'react-intersection-observer';

import { ICmsFooter } from 'epromo-lib-cms/types/payload-types';
import { CategoryTreeItem } from 'epromo-types/Inventory';
import { IBaseAppProps } from 'epromo-types';
import { useInitialCategories } from 'epromo-lib/hooks/useInitialCategories';

import { BackTopButton } from '@components/atoms/BackTopButton';
import { Footer } from '@components/organisms/Footer';
import { HeaderNavigation } from '@components/organisms/HeaderNavigation';

export interface LandingLayoutProps extends IBaseAppProps {
  children?: ReactNode;
  category?: CategoryTreeItem;
  footerData?: ICmsFooter;
  tokenExists: boolean;
}

export interface LandingLayoutProps extends IBaseAppProps {
  children?: ReactNode;
  category?: CategoryTreeItem;
  footerData?: ICmsFooter;
  tokenExists: boolean;
}

export function LandingLayout({
  children,
  footerData,
  tokenExists,
  deviceInfo,
  closedNotifications,
  notificationToDisplay,
  nativeAppNotificationStatus,
}: LandingLayoutProps) {
  const { flatInventoryList, isLoading } = useInitialCategories();
  const [btnInView, setBtnInView] = useState(false);

  return (
    <div className="landing bg-white">
      <div className="h-full min-h-full">
        <HeaderNavigation
          isSticky
          isLoading={isLoading}
          flatInventoryList={flatInventoryList}
          tokenExists={tokenExists}
          deviceInfo={deviceInfo}
          closedNotifications={closedNotifications}
          notificationToDisplay={notificationToDisplay}
          nativeAppNotificationStatus={nativeAppNotificationStatus}
        />
        <InView
          onChange={(isInView) => {
            setBtnInView(isInView);
          }}
        />
        <div className="w-full flex-initial">{children}</div>
        {footerData && <Footer {...footerData} />}
        <BackTopButton
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          btnInView={btnInView}
        />
      </div>
    </div>
  );
}
